export const environment = {
    ApiUrl: 'https://adminapi.blue-version.com/api/',
    BaseApiUrl: 'https://api.blue-version.com/api/',
    SportApiUrl: 'https://tickets.agents.zone/',
    CMSApiUrl: 'https://api.blue-version.com/api/Identity/',
    ImageCDNUrl: 'https://cdn.blue-version.com',
    CDNUrl: 'https://cdn.blue-version.com',
    AGGridLicence: 'CompanyName=CG Soft LLC,LicensedApplication=CGS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-026402,ExpiryDate=17_March_2023_[v2]_MTY3OTAxMTIwMDAwMA==dd30e37fd5c9e3f4f9954b9b6d22b38e',
    production: true
};
