import { UrlMatchResult, UrlSegment } from '@angular/router';

export const paymentsMatcher = (url: UrlSegment[]): UrlMatchResult | null => {
    if (url[0].path === 'deposits' || url[0].path === 'withdrawals') {
        return {
            consumed: url.slice(0, 1),
            posParams: {
                paymentRequestType: new UrlSegment(url[0].path, {})
            }
        };
    }
    return null;
};
