export enum AdminSettingsGroupEnum{
    General = 'General',
    Integration = 'Integration',
    StylesAndColors = 'Styles and Colors'
}

export enum StylesAndColorsTypeEnum {
    LightStyle = 'Light Style',
    DarkStyle = 'Dark Style',
}

export enum IntegrationTypeEnum {
    GoogleCaptcha = 'Google Captcha',
    RSANetworkSecurity = 'RSA Network Security',
    Fingerprint = 'Fingerprint',
}

export enum IntegrationCategoryEnum {
    BotProtection = 'Bot Protection',
    DeviceDetection = 'Device Detection',
}

export enum IntegrationStatusEnum {
    Active = 1,
}
