export enum CMSEnumKeys {
    Mode = 'Mode',
    Statuses = 'Statuses',
    Positions = 'Positions',
    Structures = 'Structures',
    BlockVisibility = 'BlockVisibility',
}

export enum CMSBannerEnumKeys {
    BannerVisibility = 'BannerVisibility',
}

export enum CMSAuditEnumKeys {
    ActionTypes = 'ActionTypes',
}

export enum CMSNavigationEnumKeys {
    BlockTitleVisibility = 'BlockTitleVisibility',
    Routings = 'Routings',
    SliderTypes = 'SliderTypes',
    BadgeTypes = 'BadgeTypes'
}

export enum CMSSEOEnumKeys {
    SeoPageWorkStatus = 'SeoPageWorkStatus',
}

export enum CMSSEOUpdateEnumKeys {
    SeoPageWorkStatus = 'SeoPageWorkStatus',
    SeoMetaType = 'SeoMetaType',
    SeoMetaContentType = 'SeoMetaContentType',
}

export enum CMSStyleEnumKeys {
    StyleTypes = 'StyleTypes',
}

export enum CMSCountryEnumKeys {
    MobileStatuses = 'MobileStatuses',
    RegionSection = 'RegionSection',
}

export enum CMSSportEnumKeys {
    SportMatchType = 'SportMatchType',
}

export enum CMSRegistrationEnumsKeys {
    RegistrationTypes = 'RegistrationTypes',
    FieldsType = 'FieldsType',
    FieldsStatus = 'FieldsStatus',
    FieldsFillingType = 'FieldsFillingType',
    ImagePosition = 'ImagePosition',
    FieldsSize = 'FieldsSize',
}

export enum CMSFooterContentEnumKeys {
    Routings = 'Routings',
}

export enum CMSStorageEnumKeys {
    BannerTypes = 'banner-types',
    BannerVisibility = 'banner-visibility',
    Mode = 'mode',
    Statuses = 'statuses',
    StyleTypes = 'style-types',
    BlockTitleVisibility = 'block-title-visibility',
    BlockVisibility = 'block-visibility',
    Positions = 'positions',
    Structures = 'structures',
    Routings = 'routings',
    SliderTypes = 'slider-types',
    BadgeTypes = 'badge-types',
    SeoPageWorkStatus = 'seo-page-work-status',
    SeoMetaType = 'seo-meta-type',
    SeoMetaContentType = 'seo-meta-content-type',
    MobileStatuses = 'mobile-statuses',
    RegionSection = 'region-section',
    RegistrationTypes = 'registration-types',
    FieldsType = 'fields-type',
    FieldsStatus = 'fields-status',
    FieldsFillingType = 'fields-filling-type',
    ImagePosition = 'image-position',
    ButtonType = 'button-type',
    FieldsSize = 'fields-size',
    SportMatchType = 'sport-match-type',
    ActionTypes = 'action-types',
}
